import { useDateFormat } from '@vueuse/core';

export function generateSchemas() {
  const { useUrl } = useUrls();
  const { useImage } = useImages();
  const {
    t,
    localeProperties,
  } = useI18n();

  const defineLocationSchema = (type, locationData, metaData) => {
    const result = {
      '@type': type,
      'name': locationData?.name,
      'description': metaData?.description,
      'image': {
        '@type': 'ImageObject',
        'url': metaData?.ogImage,
      },
      'url': metaData?.canonicalUrl,
    };

    if (
      (locationData?.geoCoordinates?.latitude && locationData?.geoCoordinates?.longitude)
      || (locationData?.latitude && locationData?.longitude)
    ) {
      result.geo = {
        '@type': 'GeoCoordinates',
        'latitude': locationData?.geoCoordinates?.latitude || locationData?.latitude,
        'longitude': locationData?.geoCoordinates?.longitude || locationData?.longitude,
      };
    }

    return result;
  };

  const defineFaqSchema = (faqData) => {
    if (!faqData) {
      return;
    }

    const result = {
      '@type': 'FAQPage',
      'mainEntity': [],
    };

    result.mainEntity = faqData.map((item) => ({
      '@type': 'Question',
      'name': item?.header,
      'acceptedAnswer': {
        '@type': 'Answer',
        'text': item?.content?.replace(/(<([^>]+)>)/g, ''),
      },
    }));

    return result;
  };

  const definePropertySchema = (property, propertyTypeObj, metaData, propertyMapLink) => {
    // https://developers.google.com/search/docs/appearance/structured-data/vacation-rental
    const result = {
      '@type': 'LodgingBusiness',
      'additionalType': propertyTypeObj?.category || 'Hostel',
      'name': property?.name,
      'description': metaData?.ogDescription,
      'url': metaData?.canonicalUrl,
      'hasMap': propertyMapLink,
      'priceRange': `${property?.lowestPricePerNight?.currency} ${property?.lowestPricePerNight?.value}`,
      'image': property?.images?.map((image) => useImage.getImage(image?.large)),
      'photo': {
        '@type': 'ImageObject',
        'name': property.name,
        'url': metaData.ogImage,
      },
      'address': {
        '@type': 'PostalAddress',
        'postalCode': property?.location?.address?.postalCode || 'N/A',
        'addressLocality': property?.location?.city?.name,
        'addressCountry': property?.location?.country?.name,
        'addressRegion': property?.location?.country?.name, // @TODO Add proper region
        'streetAddress': property?.location?.address?.line1,
      },
      'telephone': 'N/A', // @TODO Add telephone number
      'geo': {
        '@type': 'GeoCoordinates',
        'latitude':
          property?.location?.geoCoordinates?.latitude || property?.latitude || property?.geoCoordinates?.latitude,
        'longitude':
          property?.location?.geoCoordinates?.longitude || property?.longitude || property?.geoCoordinates?.longitude,
      },
    };

    if (property?.facilities?.length > 0) {
      result.amenityFeature = property?.facilities?.map((facilityType) => {
        if (facilityType?.facilities?.length > 0) {
          return facilityType?.facilities?.map((facility) => {
            return {
              '@type': 'LocationFeatureSpecification',
              'name': t(`t_${facility.name}`),
              'value': true,
            };
          });
        }
        return null;
      });
    }

    if (property?.reviews?.totalAverageRating > 0 && property?.reviews?.totalCount > 0) {
      result.aggregateRating = {
        'name': property?.name,
        '@type': 'AggregateRating',
        'ratingValue': property?.reviews?.totalAverageRating,
        // "ratingCount": 10, // @TODO This should be the total number of ratings the property has received
        'reviewCount': property?.reviews?.totalCount,
        'bestRating': 10,
      };
    }

    if (property?.policies?.checkIn && property?.policies?.checkOut) {
      const checkinTime = useDateFormat(`2024-11-11 ${property?.policies?.checkIn?.startsAt}`, 'HH:mm:ss', { locales: localeProperties?.value?.language });
      const checkoutTime = useDateFormat(`2024-11-11 ${property?.policies?.checkOut?.endsAt}`, 'HH:mm:ss', { locales: localeProperties?.value?.language });
      result.checkinTime = checkinTime;
      result.checkoutTime = checkoutTime;
    }

    if (property?.reviews?.totalAverageRating > 0 && property?.reviews?.latest?.length > 0) {
      result.review = property?.reviews?.latest?.map((review) => {
        return {
          '@type': 'Review',
          'reviewRating': {
            '@type': 'Rating',
            'ratingValue': review?.rating,
            'bestRating': 10,
          },
          'author': {
            '@type': 'Person',
            'name': review?.guestFirstname,
          },
          'datePublished': review?.stayed,
          'contentReferenceTime': review?.stayed,
        };
      });
    }

    return result;
  };

  const defineListOfPropertiesSchema = (properties, locationData, propertyTypeObj) => {
    if (properties?.length === 0) {
      return;
    }

    const result = {
      '@type': 'ItemList',
      'name': 'List of Properties',
      'itemListElement': [],
    };

    result.itemListElement = properties?.map((property, index) => {
      const innerResult = {
        '@type': 'ListItem',
        'position': index + 1,
        'item': {
          '@type': 'LodgingBusiness',
          'additionalType': propertyTypeObj?.category || 'Hostel',
          'name': property?.name,
          'description': property?.intro,
          'url': useUrl.getPropertyPageUrl({
            propertyTypeSlug: propertyTypeObj?.slug,
            propertyId: property?.id,
            urlFriendlyProperty: property?.urlFriendlyName,
          }),
          'image': property?.images?.map((image) => useImage.getImage(image?.large)),
          'address': {
            '@type': 'PostalAddress',
            'streetAddress': property?.address,
            'addressLocality': locationData?.city || locationData?.name,
            'addressCountry': locationData?.country,
            'addressRegion': locationData?.country, // @TODO Add proper region
            'postalCode': 'N/A', // @TODO Add postal code
          },
          'telephone': 'N/A', // @TODO Add telephone number
          'geo': {
            '@type': 'GeoCoordinates',
            'latitude': property?.latitude || property?.geoCoordinates?.latitude,
            'longitude': property?.longitude || property?.geoCoordinates?.longitude,
          },
          // 'priceRange': '$', // @TODO Check a way to determine what is the price range
          // 'amenityFeature': [], // @TODO Add amenities
        },
      };

      if (property?.avgRating > 0 && property?.numberReviews > 0) {
        innerResult.item.aggregateRating = {
          'name': property?.name,
          '@type': 'AggregateRating',
          'ratingValue': property?.avgRating,
          // "ratingCount": 10, // @TODO This should be the total number of ratings the property has received
          'reviewCount': property?.numberReviews,
          'bestRating': 10, // @TODO This is the maximum rating value, but should be the best rating the property has gotten
        };
      }

      return innerResult;
    });

    return result;
  };

  const defineListOfPopularPlacesSchema = (popularPlaces, locationData, propertyTypeObj, metaData) => {
    if (popularPlaces?.length === 0) {
      return;
    }

    const result = {
      '@type': 'TouristDestination',
      'name': locationData?.name,
      'description': metaData?.description,
      'image': {
        '@type': 'ImageObject',
        'url': metaData?.ogImage,
      },
      'url': metaData?.canonicalUrl,
      'includesAttraction': [],
    };

    if (
      (locationData?.geoCoordinates?.latitude && locationData?.geoCoordinates?.longitude)
      || (locationData?.latitude && locationData?.longitude)
    ) {
      result.geo = {
        '@type': 'GeoCoordinates',
        'latitude': locationData?.geoCoordinates?.latitude || locationData?.latitude,
        'longitude': locationData?.geoCoordinates?.longitude || locationData?.longitude,
      };
    }

    result.includesAttraction = popularPlaces?.map((place) => {
      return {
        '@type': 'TouristAttraction',
        'name': place.name,
        'description': place.name,
        'url': place?.url,
      };
    });

    return result;
  };

  return {
    defineLocationSchema,
    defineFaqSchema,
    definePropertySchema,
    defineListOfPropertiesSchema,
    defineListOfPopularPlacesSchema,
  };
}
